import React, { useEffect }  from 'react';
import {useTranslation, withTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useParams, useNavigate, useLocation, Outlet} from 'react-router-dom';
import {Helmet, HelmetProvider} from 'react-helmet-async';
import { library, dom } from '@fortawesome/fontawesome-svg-core';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import {setLanguage, setQuote} from './features/configurator/configuratorSlice';
import './App.scss';

library.add(fas, fab, far);
dom.watch()

function App() {
  const location = useLocation();
  const navigate = useNavigate();
  const { language } = useParams();
  const currentLanguage = useSelector(state => state.configurator.language);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  // Set language on page load (from URL params)
  useEffect(() => {
    dispatch(setLanguage(language));
  }, [dispatch, language]);

  // Change locale in URL if language change
  useEffect(() => {
    navigate(location.pathname.replace(/^\/.{2}\//, `/${currentLanguage}/`))
  }, [currentLanguage]);

  // Load data from local storage
  useEffect(() => {
    const quote = localStorage.getItem('quote');
    if (quote) {
      dispatch(setQuote(JSON.parse(quote)));
    }
  }, [dispatch]);

  return (
    <HelmetProvider>
      <Helmet>
        <html lang={currentLanguage} translate="no"/>
        <title>{t('meta.default.title')}</title>
        <meta name="description" content={t('meta.default.description')}/>
      </Helmet>
      <section className='app-container'>
        <Outlet/>
      </section>
    </HelmetProvider>
  );
}

export default withTranslation()(App);
